export var tableField = [
  { label: '序号', code: 'onlineInvoiceLine', type: 'input', width: '' },
  { label: '对账单编号', code: 'number', type: 'input', width: '130' },
  { label: '对账单行号', code: 'lineNumber', type: 'input', width: '110' },
  { label: '采购单编号', code: 'purchaseOrderNumber', type: 'input', width: '100' },
  { label: '采购单行号', code: 'purchaseLineNumber', type: 'input', width: '100' },
  { label: '物料编号', code: 'itemNumber', type: 'input', width: '' },
  { label: '物料描述 ', code: 'materialDescription', type: 'input', width: '160' },
  { label: '收货数量 ', code: 'quantityReceived', type: 'input', width: '' },
  { label: '开票数量 ', code: 'numberOfOnlineInvoices', type: 'input', width: '' },
  { label: '单价', code: 'unitPrice', type: 'amount', align: 'right', width: '' },
  { label: '含税金额', code: 'onlineInvoiceIncludingTax', type: 'amount', align: 'right', width: '120' },
  { label: '未税金额', code: 'noTaxOfOnlineInvoice', type: 'amount', align: 'right', width: '120' },
  { label: '税率', code: 'taxRate', type: 'input', width: '' },
  { label: '税额', code: 'noCurrentTaxAmount', type: 'amount', align: 'right', width: '' },
  { label: '收货基地', code: 'base', type: 'input', width: '' },
  { label: '内部项目号', code: 'internalProjectNumber', type: 'input', width: '120' },
  { label: '内部项目标识', code: 'internalProjectIdentification', type: 'input', width: '120' },
  {
    label: '物料凭证号',
    code: 'materialVoucherNo',
    width: '150',
    type: 'vnode',
    handle: function (scope) {
      if (scope) {
        return {
          methods: {
            clk: () => {
              this.$emit('diashow', scope)
            }
          },
          template: '<span><el-button size="mini" type="text" @click="clk">' + scope.row.materialVoucherNo + '</el-button></span>'
        }
      }
    }
  }
]
export var MaterialData = [
  { label: '物料编号', code: 'itemNumber', type: 'input', width: '' },
  { label: '物料描述 ', code: 'materialDescription', type: 'input', width: '160' },
  { label: '我的物料编号', code: 'myItemNumber', type: 'input', width: '110' },
  { label: '我的物料描述', code: 'myMaterialDescription', type: 'input', width: '110' },
  { label: '收货数量 ', code: 'quantityReceived', type: 'input', width: '' },
  { label: '开票数量 ', code: 'numberOfOnlineInvoices', type: 'input', width: '' },
  { label: '单价', code: 'unitPrice', type: 'amount', align: 'right', width: '' },
  { label: '含税金额', code: 'onlineInvoiceIncludingTax', type: 'amount', align: 'right', width: '120' },
  { label: '未税金额', code: 'noTaxOfOnlineInvoice', type: 'amount', align: 'right', width: '120' },
  { label: '税率', code: 'taxRate', type: 'input', width: '' },
  { label: '税额', code: 'noCurrentTaxAmount', type: 'amount', align: 'right', width: '' }
]
export var tableData = []
