<template>
    <div class="mainform">
        <!-- 采购网上发票详情 -->
        <div class="form">
            <div class="header">网上发票信息<span @click="back" class="back">返回></span> </div>
              <el-form  class="content bascform" :model="form" :class="{disabled:formDisabled}" :disabled="formDisabled" label-width="120px">
                <div  class="col col4">
                  <el-form-item label="网上发票号">
                      <el-input v-model="form.onlineInvoiceNumber"></el-input>
                  </el-form-item>
                </div>
                <div  class="col col4">
                  <el-form-item label="供应商">
                    <span class="onlyText">
                      {{form.supplierCode+'-'+form.supplier}}
                    </span>
                  </el-form-item>
                </div>
                <div  class="col col4">
                  <el-form-item label="公司名称">
                    <span class="onlyText">
                      {{form.corporateCode+'-'+form.corporateName}}
                    </span>
                  </el-form-item>
                </div>
                <div  class="col col4">
                  <el-form-item label="开票方">
                      <span class="onlyText">
                      {{form.billingPartyCode+'-'+form.billingParty}}
                    </span>
                  </el-form-item>
                </div>
                <div class="col col4">
                  <el-form-item label="是否寄售">
                    <el-select v-model="form.consignmentOrNot" >
                      <el-option label="是" :value="0"></el-option>
                      <el-option label="否" :value="1"></el-option>
                      </el-select>
                  </el-form-item>
                </div>
                <div class="col col4">
                  <el-form-item label="对账单类型">
                    <el-select v-model="form.statementType" >
                        <el-option label="正常采购" :value="0"></el-option>
                        <el-option label="分期付款" :value="1"></el-option>
                      </el-select>
                  </el-form-item>
                </div>
                <div  class="col col4">
                  <el-form-item label="币种">
                      <el-input v-model="form.currency"></el-input>
                  </el-form-item>
                </div>
                <div class="col col4">
                  <el-form-item label="是否红字发票">
                    <span class="onlyText">
                      {{form.redInkMark=='X'?'是':'否'}}
                    </span>
                  </el-form-item>
                </div>
                <div  class="col col4">
                  <el-form-item label="含税总额">
                    <el-input v-model="form.totalIncludingTax"></el-input>
                  </el-form-item>
                </div>
                <div class="col col4">
                  <el-form-item label="付款状态">
                    <el-select v-model="form.paymentStatus" >
                      <el-option label="已付款" :value="0"></el-option>
                      <el-option label="未付款" :value="1"></el-option>
                      <el-option label="部分付款" :value="2"></el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div  class="col col4">
                  <el-form-item label="未税总额">
                    <el-input v-model="form.totalNotTaxed"></el-input>
                  </el-form-item>
                </div>
                <div class="col col4">
                  <el-form-item label="网上发票状态">
                    <el-select v-model="form.onlineInvoiceStatus" >
                      <el-option label="待匹配发票" :value="0"></el-option>
                      <el-option label="已作废" :value="1"></el-option>
                      <el-option label="已完成" :value="2"></el-option>
                      <el-option label="取消记账" :value="3"></el-option>
                      <el-option label="记账失败" :value="4"></el-option>
                      <el-option label="待签收" :value="5"></el-option>
                      <el-option label="已签收" :value="6"></el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div  class="col col4">
                  <el-form-item label="总税额">
                    <el-input v-model="form.totalOnlineInvoiceTax"></el-input>
                  </el-form-item>
                </div>
                <div  class="col col4">
                  <el-form-item label="创建日期">
                    <el-input v-model="form.createDate"></el-input>
                  </el-form-item>
                </div>
              </el-form>
            <div class="header">网上发票明细
              <span class="showOff">
                <el-button @click="open" size="small" type="text">{{isclose?"展开":"收起"}}
                  <i :class="[{'el-icon-arrow-down':isclose},{'el-icon-arrow-up':!isclose}]"></i>
                </el-button>
              </span>
            </div>
            <global-table v-if="isclose" class=" bascform" @diashow="diaShow" max-height="350" :tableField="tableField" :tableData="tableData" @tableAction="tableAction"></global-table>
            <div class="header">物料汇总
              <span class="showOff">
                <el-button @click="show" size="small" type="text">{{isShow?"展开":"收起"}}
                  <i :class="[{'el-icon-arrow-down':isShow},{'el-icon-arrow-up':!isShow}]"></i>
                </el-button>
              </span>
            </div>
            <global-table v-if="isShow" class="bascform"  max-height="350" :tableField="MaterialData" :tableData="materialList" ></global-table>
            <div class="header">税务发票匹配</div>
            <div class=" bascform">
                <div class="tableList" >
                  <div class="table">
                    <el-table :data="taxinvoicedata"  style="width: 100%">
                        <el-table-column prop="invoiceCode" label="发票代码" > </el-table-column>
                        <el-table-column prop="invoiceNo" label="发票号码" width='200px' ></el-table-column>
                        <el-table-column prop="totalAmount" label="含税金额" > </el-table-column>
                        <el-table-column prop="invoiceAmount" label="未税金额" ></el-table-column>
                        <el-table-column prop="taxAmount" label="税额" ></el-table-column>
                        <el-table-column prop="invoiceDate" label="开票日期" > </el-table-column>
                        <el-table-column prop="isEleSignFor" label="签收状态" >
                          <template #default="scope">
                            {{scope.row.isEleSignFor==0?'已签收' :scope.row.isEleSignFor==1?'未签收': '取消签收'}}
                          </template>
                        </el-table-column>
                        <el-table-column prop="qsDate" label="签收日期" > </el-table-column>
                        <el-table-column prop="remark" label="备注" > </el-table-column>
                    </el-table>
                  </div>
                </div>
            </div>
        </div>
        <dialog-box  ref="dialogbox" dialogWidth="50%" :showData="showData" :dialogShow="dialogShow" @handleClose='handleClose'  title="物料凭证" componentName="MVoucherNoDialog"
                :buttonData="[{ label: '关闭', action: 'handleClose', size: 'mini' }]">
        </dialog-box>
    </div>
</template>

<script>

import Component from '@/assets/js/components.js'
import { tableField, tableData, MaterialData } from './js/purchaseInvoiceDetail'
import { requestForm } from '@/assets/js/http.js'
export default {
  components: Component.components,
  name: 'PurchaseInvoiceDetail',
  data () {
    return {
      tableField: tableField,
      tableData: tableData,
      materialList: [],
      MaterialData: MaterialData,
      formDisabled: true,
      dialogShow: false,
      form: {},
      isclose: true,
      isShow: true,
      showData: {},
      taxinvoicedata: []
    }
  },
  mounted () {
    this.invioceDetail()
  },
  methods: {
    invioceDetail () { // 网上发票明细
      const id = this.$route.query.id
      requestForm('/api/reconciliation/invoiceTotal/queryOne?id=' + id, 'post').then((res) => {
        if (res.code === '200') {
          this.form = res.data
          this.tableData = res.data.reconciliationList
          this.taxinvoicedata = res.data.invoiceList
          this.materialList = res.data.materialList
          this.tableData.forEach(item => {
            this.form.materialVoucherNo = item.materialVoucherNo
          })
        }
      })
    },
    diaShow (scope) {
      var number = scope.row.materialVoucherNo
      requestForm('/api/reconciliation/materialDocument/queryVoucherNo?number=' + number, 'post', '').then((res) => {
        if (res.code === '200') {
          this.showData = res.data
          this.dialogShow = true
        }
      })
    },
    tableAction () {
      this.dialogShow = true
    },
    handleClose () {
      this.dialogShow = false
    },
    open () {
      this.isclose = !this.isclose
    },
    show () {
      this.isShow = !this.isShow
    },
    back: () => {
      window.history.back()
    }
  }
}
</script>
<style scoped lang="scss">
  @import '@/assets/css/form.scss';
  @import '@/assets/css/elForm.scss';
  @import '@/assets/css/tableList.scss';
  .disabled {
  .col{
    margin: 3px 0 !important;
  }
}
  .back{
    float: right;
    color: #00aaff;
    font-size: 16px;
    cursor: pointer;
  }
  .tableList{
      padding: 0px;
  }
  .showOff{
  float: right;
}
</style>
